import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, InlineTextButton } from '../../components';
import { richText } from '../../util/richText';

import { getStoredLanguage, detectLanguage } from '../../util/translations.js';
import translateWeglot from '../../translations/useWeglot.js';
import { calculatePostLanguage } from '../../components/TranslateButton/TranslateButton.js';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

/**
 * Utility to create HTML content from plain text.
 */
const createContent = text =>
  richText(text, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

/**
 * SectionTextMaybe:
 * - Displays a listing description (passed in as `text`).
 * - Optionally translates the text on button click (using Weglot).
 * - Shows loading/fallback states if `text` is not yet available.
 */
const SectionTextMaybe = props => {
  const { text, heading, currentAuthor, showAsIngress = false } = props;

  // Styling for the text
  const textClass = showAsIngress ? css.ingress : css.text;

  // Track whether we are showing translated text
  const [isTranslated, setIsTranslated] = useState(false);
  const [translationFailed, setTranslationFailed] = useState(false);
  const [showButton, setShowButton] = useState(true);

  // Create HTML content from the original text
  const [translatedRichText, setTranslatedRichText] = useState('');

  // -----------------------------------------------------
  // 1) Update content whenever `text` changes
  //    (i.e., after the listing data finishes loading).
  // -----------------------------------------------------
  useEffect(() => {
    if (text && text.trim().length > 0) {
      // Set the rich text with the original content.
      const originalContent = createContent(text);
      setTranslatedRichText(originalContent);

      // Check if we actually need the "Translate" button
      // E.g., if the detected language equals current user’s language, we can hide it.
      detectLanguage(text)
        .then(langCode => {
          if (!langCode) {
            // If detection fails, try fallback from author's profile or default
            langCode = calculatePostLanguage(currentAuthor);
          }
          const userLang = getStoredLanguage();

          // If text is already in the user's language, no need for translation button
          if (langCode === userLang) {
            setShowButton(false);
          } else {
            setShowButton(true);
          }
        })
        .catch(err => {
          console.error('Language detection failed:', err);
          setShowButton(true);
        });
    } else {
      // No text -> show empty, fallback, or loading state
      setTranslatedRichText('');
    }

    // Reset translation states whenever text changes
    setIsTranslated(false);
    setTranslationFailed(false);
  }, [text, currentAuthor]);

  // -----------------------------------------------------
  // 2) Handle the "Translate" / "Show original" button
  // -----------------------------------------------------
  const handleTranslate = async () => {
    if (!text || text.trim().length === 0) {
      return;
    }
    if (!isTranslated) {
      // Attempt to translate
      try {
        const sourceLang = await detectLanguage(text);
        const userLang = getStoredLanguage();

        // If detection fails, use fallback from author's profile
        const fallbackLang = calculatePostLanguage(currentAuthor);
        const finalLang = sourceLang || fallbackLang;

        if (!finalLang) {
          console.log('No valid sourceLang found. Aborting translation.');
          return;
        }

        const translated = await translateWeglot(text, finalLang, userLang);

        if (translated) {
          setIsTranslated(true);
          setTranslationFailed(false);
          setTranslatedRichText(createContent(translated));
        } else {
          console.log('Translation failed');
          setTranslationFailed(true);
        }
      } catch (err) {
        console.log('Translation error:', err);
        setTranslationFailed(true);
      }
    } else {
      // Already translated, so revert to original
      setIsTranslated(false);
      setTranslationFailed(false);
      setTranslatedRichText(createContent(text));
    }
  };

  // -----------------------------------------------------
  // 3) Render logic:
  //    - Loading or fallback if `text` is missing
  //    - Description
  //    - Translate button (if relevant)
  // -----------------------------------------------------
  const hasText = text && text.trim().length > 0;

  // If we have no text at all, show fallback or nothing
  if (!hasText) {
    return (
      <section className={css.sectionText}>
        {heading ? (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        ) : null}
        <p className={css.noDescriptionText}>
          <FormattedMessage id="ListingPage.noDescriptionFallback" />
        </p>
      </section>
    );
  }

  return (
    <section className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      {/* Render the (translated or original) content */}
      <p className={textClass}>{translatedRichText}</p>

      {/* Show translate button if there's text and userLang differs */}
      {showButton ? (
        <div className={css.translateButtonDiv}>
          <InlineTextButton className={css.translateButton} onClick={handleTranslate}>
            <FormattedMessage
              id={
                translationFailed
                  ? 'ListingPage.translationFailed'
                  : isTranslated
                  ? 'ListingPage.showOriginal'
                  : 'ListingPage.translate'
              }
            />
          </InlineTextButton>
        </div>
      ) : null}
    </section>
  );
};

export default SectionTextMaybe;
