import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import {
  filterparamsTranslations,
  getTranslation
} from '../../translations/TranslationsProvider';

import css from './ListingPage.module.css';

/**
 * SectionDetailsMaybe:
 * Renders a list of custom listing fields (including description if configured)
 * and some availability data if time slots are pre-fetched.
 */
const SectionDetailsMaybe = props => {
  const {
    publicData,
    metadata = {},
    listingFieldConfigs,
    isFieldForCategory,
    monthlyTimeSlots,
    intl,
  } = props;

  // ---------------------------------------------------------------------------
  // 1) EARLY RETURN FOR MISSING DATA
  // If the parent hasn’t passed listing data or field configs yet, show loading.
  // ---------------------------------------------------------------------------
  if (!publicData || !listingFieldConfigs) {
    return (
      <section className={css.sectionDetails}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </Heading>
        <p className={css.loadingText}>
          <FormattedMessage id="ListingPage.loadingDetailsMessage" />
        </p>
      </section>
    );
  }

  /**
   * Pick listing fields to show as "details" from the config array.
   * Only fields with `isDetail` (and matching listingType, category, etc.) appear.
   */
  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue; // Merged value from publicData or metadata

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      // Convert enum or boolean values to user-friendly labels
      const findSelectedOption = enumValue =>
        enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = val =>
        val
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });

      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({
            key,
            value: optionConfig?.label,
            label,
          })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({
            key,
            value: getBooleanMessage(value),
            label,
          })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  // Create an array of "detail" items to show
  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);

  // ---------------------------------------------------------------------------
  // 2) PROCESS TIME SLOTS
  //    If your listings use availability data, this logic fetches upcoming slots
  //    and returns them in a single array for display.
  // ---------------------------------------------------------------------------
  const processTimeSlots = timeSlotsObj => {
    // Are we still fetching timeslots?
    const allFetchesDone = Object.values(timeSlotsObj).every(
      month => !month.fetchTimeSlotsInProgress
    );

    if (!allFetchesDone) {
      return [];
    }

    // Merge all monthly time slots into one array
    const timeSlotsArray = Object.values(timeSlotsObj).reduce((acc, month) => {
      if (month.timeSlots) {
        const slots = month.timeSlots.map(slot => [
          slot.attributes.start,
          slot.attributes.end,
          slot.attributes.seats,
        ]);
        return acc.concat(slots);
      }
      return acc;
    }, []);

    return timeSlotsArray;
  };

  const processedTimeSlots = processTimeSlots(monthlyTimeSlots);

  // Simple weekday formatting for availability
  const weekDayFormatted = dayOfWeek => {
    const ids = [
      'EditListingAvailabilityPlanForm.dayOfWeek.sun',
      'EditListingAvailabilityPlanForm.dayOfWeek.mon',
      'EditListingAvailabilityPlanForm.dayOfWeek.tue',
      'EditListingAvailabilityPlanForm.dayOfWeek.wed',
      'EditListingAvailabilityPlanForm.dayOfWeek.thu',
      'EditListingAvailabilityPlanForm.dayOfWeek.fri',
      'EditListingAvailabilityPlanForm.dayOfWeek.sat',
    ];
    return intl.formatMessage({ id: ids[dayOfWeek] });
  };

  /**
   * Format a single availability slot as:
   *    "Monday, 2025-01-20, 10:00  x seats"
   */
  const formatAvailabilityMessage = (slots, indexInSlot, slotIndex = 0) => {
    const timeSlot = slots[slotIndex];
    // timeSlot[0] = start, timeSlot[1] = end, timeSlot[2] = seats
    const dateObj = timeSlot[indexInSlot];
    const weekDay = weekDayFormatted(dateObj.getDay());
    const dateString = dateObj.toLocaleDateString('sv');
    const timeString = dateObj.toLocaleTimeString('sv').substr(0, 5);
    const seatCount = timeSlot[2];

    // Only display seats count on the 'start' row (indexInSlot === 0)
    const seatsMessage =
      indexInSlot === 0
        ? intl.formatMessage(
            { id: 'EditListingAvailabilityPanel.WeeklyCalendar.availableSpots' },
            { seats: seatCount }
          )
        : '';

    return `${weekDay}, ${dateString}, ${timeString} ${seatsMessage}`;
  };

  // ---------------------------------------------------------------------------
  // 3) RENDER
  //    - If there are details to display, show them in a <ul>.
  //    - If we have time slots, show "next availability" info.
  // ---------------------------------------------------------------------------
  return existingListingFields.length > 0 ? (
    <>
      <section className={css.sectionDetails}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </Heading>
        <ul className={css.details}>
          {existingListingFields.map(detail => {
            // Translate filter parameter labels/values (if using custom translations)
            detail.label = getTranslation(filterparamsTranslations, detail.label);
            detail.value = getTranslation(filterparamsTranslations, detail.value);

            return (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>{detail.label}</span>
                <span>
                  {/* If this is the description field, you can add a fallback here if needed */}
                  {detail.value || (
                    <FormattedMessage id="ListingPage.noDescriptionFallback" />
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      </section>

      {/* If processedTimeSlots contains upcoming availability, display it */}
      {processedTimeSlots.length > 0 ? (
        <section className={css.sectionDetails}>
          <Heading as="h2" rootClassName={css.sectionHeading}>
            <FormattedMessage id="ListingPage.availabilityNext" />
          </Heading>
          <ul className={css.details}>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.availabilityStartsAt" />
              </span>
            </li>
            <li className={css.detailsRow}>
              <span>{formatAvailabilityMessage(processedTimeSlots, 0)}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.availabilityEndsAt" />
              </span>
            </li>
            <li className={css.detailsRow}>
              <span>{formatAvailabilityMessage(processedTimeSlots, 1)}</span>
            </li>
          </ul>
        </section>
      ) : null}
    </>
  ) : (
    // If there are no fields to show at all, you can display a fallback or nothing
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <p className={css.noDetailsText}>
        <FormattedMessage id="ListingPage.noDetailsMessage" />
      </p>
    </section>
  );
};

export default SectionDetailsMaybe;
